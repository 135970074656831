<template>
  <v-dialog v-model="memoValidationDialog" persistent max-width="400">
    <v-card v-if="singleMemo">
      <v-card-title class="headline">
        {{ $t("card.memo-qr-validation") }}
      </v-card-title>
      <v-card-text class="pa-2 text-center align-center">
        <QRCanvas :options="qrCodeOption" @updated="onUpdated" />

        <v-card-text class="text-center align-center">
          <p v-html="$t('text.use-your-qrscan-app')"></p>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              large
                v-on="on"
                v-bind="attrs"
                class="mx-2"
                color="primary"
                fab
                outlined
                @click="openValidationUrl"
              >
                <v-icon>{{ iconQr }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.open-validation-url") }}</span>
          </v-tooltip>
        </v-card-text>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
          <v-btn
          outlined
          color="red darken-1"
          @click="memoValidationDialog = false"
        >
          <v-icon class="mr-2">{{ iconClose }}</v-icon>
          {{ $t("button.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="!singleMemo">
      <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        type="article, actions"
      ></v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import { QRCanvas } from "qrcanvas-vue";
import { baseUrl } from "@/constants/config";
import { mapState } from "vuex";
import { mdiClose, mdiQrcode } from "@mdi/js";

export default {
  name: "memo-validation-modal",
  components: {
    QRCanvas,
  },
  props: {
    singleMemo: { type: Object },
  },
  data: () => ({
    iconQr: mdiQrcode,
    iconClose: mdiClose,
    imageSrc: window.location.origin + "/assets/img/icons/qrlogo.png",
    qrCodeOption: {
      cellSize: 4,
      correctLevel: "H",
      size: 80,
      data: "test",
    },
  }),
  mounted(){
    this.generateQr();
  },
  computed: {
    ...mapState(["currentThemeIsDark"]),
    memoValidationDialog: {
      get() {
        return this.$store.state.inbox.memoValidationDialog;
      },
      set(val) {
        this.$store.commit("inbox/SET_MEMO_VALIDATION_DIALOG", val);
      },
    },
  },
  watch: {
    singleMemo(newVal) {
      if (!newVal) return (this.qrCodeOption.data = "");
      this.qrCodeOption.data = `${baseUrl}/check/?v=${newVal.memo.signature}`;
    },
    currentThemeIsDark(val) {
      if (val == true) {
        this.qrCodeOption.foreground = "white";
        this.qrCodeOption.background = "#26282b";
      } else {
        this.qrCodeOption.foreground = "#26282b";
        this.qrCodeOption.background = "white";
      }
      this.generateQr();
      
    },
  },
  methods: {
    onUpdated() {
    },
    openValidationUrl() {
      window.open(this.qrCodeOption.data, "_blank");
    },
    generateQr(){
      if (this.currentThemeIsDark == true) {
        this.qrCodeOption.foreground = "white";
        this.qrCodeOption.background = "#26282b";
      } else {
        this.qrCodeOption.foreground = "#26282b";
        this.qrCodeOption.background = "white";
      }

      const image = new Image();
      image.src = window.location.origin + "/assets/img/icons/qrlogo.png";
      image.onload = () => {
        this.qrCodeOption = Object.assign({}, this.qrCodeOption, {
          logo: {
            image,
          },
        });
      };
    }
  },
};
</script>
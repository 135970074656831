<template>
  <v-card-text class="mx-2">
    <div class="table-responsive max-h-500" v-if="dispositionTrack">
      <div>
        <ul class="history-awb-timeline">
          <li v-for="(item, index) in dispositionTrack" v-bind:key="index">
            <div class="d-flex flex-row">
              <div
                class="history-date d-flex flex-column"
                v-if="isUniqueDate(index)"
              >
                <p>{{ mydate(item.created_at, "dayname") }},</p>
                <h5>{{ mydate(item.created_at, "date") }}</h5>
              </div>
              <div class="history-date d-flex flex-column" v-else>
                <p></p>
                <h5></h5>
              </div>
              <div class="history-detail d-flex flex-column">
                <p style="margin: 0px">
                  {{ mydate(item.created_at, "time") }}
                </p>
                <p style="margin: 0px" v-html="item.action"></p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </v-card-text>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "tracking-diposition",
  data: () => ({}),
  computed: {
    ...mapState("disposition", ["dispositionTrack", "dispositionFeedbacks"]),
  },
  methods: {
    isUniqueDate(trackingIndex) {
      return this.checkDateTrackingItem(trackingIndex);
    },
    checkDateTrackingItem(trackingIndex) {
      const previousItem = this.dispositionTrack[trackingIndex - 1];
      const currentItem = this.dispositionTrack[trackingIndex];
      if (!previousItem) {
        return true;
      }
      const prevDate = this.mydate(previousItem.created_at, "date");
      const currDate = this.mydate(currentItem.created_at, "date");

      if (prevDate !== currDate) return true;
      return false;
    },
  },
};
</script>

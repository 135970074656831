<template>
  <div v-if="singleMemo && singleDisposition">
    <v-list-item two-line v-if="myTask">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-avatar v-on="on" v-bind="attrs">
            <img :src="myTask.avatar_path" alt="delegate_by" v-if="myTask && myTask.creator" />
          </v-list-item-avatar>
        </template>
        <span>{{ myTask ? myTask.creator : "Atasan" }}</span>
      </v-tooltip>
      <v-list-item-content>
        <v-list-item-title class="headline">{{
          singleMemo && singleMemo.memo
            ? $t("text.memo-title") + ": " + singleMemo.memo.hal
            : $t("text.dispositions-comment-is-empty")
        }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <h4>
            <v-icon class="att-icon" color="warning" dark>
              {{ urgencyIcon(myTask.urgensi) }}
            </v-icon>
            {{ $t(urgencyLevel(myTask.urgensi)) }}
          </h4>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>

      <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
        <template v-slot:activator="{ on, attrs }">
          <span v-on="on" v-bind="attrs" class="text-right">
            {{ myTask ? $date(myTask.created_at).format("DD MMM YYYY") : "" }}
            <br />
            {{ myTask ? $date(myTask.created_at).format("HH:mm WIB") : "" }}
          </span>
        </template>
        <span>{{
          myTask
            ? $t("tooltip.received-approximately") +
            " " +
            $date(myTask.created_at).fromNow()
            : ""
        }}</span>
      </v-tooltip>
    </v-list-item>
    <v-card-text class="pt-2">
      <v-row>
        <v-col cols="12" md="6" sm="12" class="mt-sm-4">
          <h4 v-if="$vuetify.breakpoint.smAndDown" class="align-center d-flex justify-end">
            <v-icon class="mr-2">{{ iconTime }}</v-icon>
            <span>{{
              myTask
                ? $date(myTask.created_at).format("DD MMM YYYY HH:m") + " WIB"
                : ""
            }}</span>
          </h4>
          <div v-if="singleMemo.memo.tipe === 'izin_prinsip'" style="margin-bottom: 1rem;">
            <h3 class="text--bold mt-6 mb-4 mt-md-4">Persetujuan Disposisi Izin Prinsip</h3>
            <table>
              <tbody>
                <tr v-for="(item, idx) in singleDisposition.disposisi_izin_prinsip" :key="item.id_hash">
                  <td style="padding-left: 1rem;line-height: 1.5rem;">{{ `${idx + 1}.&nbsp;${item.nama}` }}:
                    <strong>{{ item.jawaban }}</strong>
                    <br>
                    <span style="padding-left: 1rem;">{{ `${$t('label.comment')}: ${item.comment}` }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p class="mt-4 mt-md-0 mb-2">
              {{ $t("text.dispositioned-by") + ": "
              }}<strong>{{ myTask.creator }}</strong>
            </p>
            <p v-if="singleDisposition">
              {{ $t("text.disposition-messages") }} :
              <v-chip class="mr-1 mb-1 font-size-default" small v-for="(message, i) in singleDisposition.messages"
                :key="i">{{ message }}</v-chip>
            </p>
            <p class="mt-4 mt-md-0 mb-2">
              {{ $t("text.disposition-direct-upline-comment") + ": "
              }}<strong>{{ myTask.comment }}</strong>
            </p>
          </div>
          <h3 class="text--bold mt-6 mb-4 mt-md-4" v-html="$t('label.disposition-recipients')"></h3>
          <v-row>
            <v-col cols="12" md="6" sm="12" v-for="item in myTask.recipients" :key="item.nik">
              <v-list-item three-line class="diposition__recipient-list"
                @click="showCompleteFeedback(null, item.feedback)">
                <v-list-item-avatar>
                  <img v-bind:src="item.avatar_path" v-bind:alt="item.nik" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ item.nama ? item.nama : "Nama Penerima Disposisi" }}
                    <v-tooltip bottom v-if="parseInt(item.is_pic) === 1">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs" style="height: 16px; width: 16px; position: absolute">
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 22px">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                                fill="#17deb6"></path>
                            </g>
                          </svg>
                        </span>
                      </template>
                      <span>{{ $t("tooltip.this person is pic") }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="font-size-smaller">{{
                    item.readed_at
                      ? $t("text.readed-at") +
                      " " +
                      $date(item.readed_at).format("DD MMM YYYY HH:m") +
                      " WIB"
                      : $t("text.unread")
                  }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{
                      item.feedback
                        ? $t("text.feedback") + ": " + item.feedback
                        : $t("text.no-feedback")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <div></div>
          <h3 class="mt-6 text--bold" v-if="singleMemo && parseInt(singleMemo.memo.is_izin_prinsip) === 1">
            {{ $t("text.permit-disposition-detail") }}
          </h3>
          <h3 class="mt-6 text--bold" v-if="myTask.parents && myTask.parents.length > 0">
            {{ $t("text.early-disposition-detail") }}
          </h3>

          <v-col cols="12" md="12" sm="12" v-for="item in recipients" :key="item.nik" class="pa-0">
            <v-list-item three-line class="permit__recipient-list" v-if="
              singleMemo && parseInt(singleMemo.memo.is_izin_prinsip) === 1
            ">
              <v-list-item-avatar>
                <img v-bind:src="item.avatar_path" v-bind:alt="item.nik" />
              </v-list-item-avatar>
              <v-list-item-content>
                <span class="approval__icon" v-if="parseInt(item.is_approved) === 1">👍🏻</span>
                <span class="approval__icon" v-else-if="parseInt(item.is_approved) === 0">✋🏻</span>
                <span class="approval__icon" v-else></span>
                <v-list-item-subtitle>{{ item.nama ? item.nama : "Nama Penerima" }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-size-smaller">{{
                  item.readed_at
                    ? $t("text.readed-at") +
                    " " +
                    $date(item.readed_at).format("DD MMM YYYY HH:m") +
                    " WIB"
                    : $t("text.unread")
                }}
                </v-list-item-subtitle>
                <v-list-item-subtitle @click="
                  showCompleteFeedback(
                    $t('msgbox.permit-comment'),
                    item.comment
                  )
                  ">
                  {{
                    item.comment
                      ? item.comment
                      : "[" + $t("text.no-answer-yet") + "]"
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-card-text class="mt-4">
            <v-row justify="center">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="item in myTask.parents" :key="item.id_hash">
                  <v-expansion-panel-header>{{
                    item.creator
                    }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="d-inline-block" v-if="item.messages && item.messages.length > 0">
                      <p class="mr-2">
                        {{ $t("text.disposition-messages") }}:&nbsp;
                      </p>
                      <v-chip class="mr-1 mb-1 font-size-default" small v-for="(message, i) in item.messages"
                        :key="i">{{
                          message }}</v-chip>
                    </div>
                    <p>
                      {{ $t("text.comment") + ": " + item.comment }}
                    </p>
                    <v-list-item two-line v-for="recipient in item.recipients" :key="recipient.nik">
                      <v-list-item-avatar>
                        <img v-bind:src="recipient.avatar_path" v-bind:alt="recipient.nik" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle>{{
                          recipient.nama
                            ? recipient.nama
                            : "Nama Penerima Disposisi"
                        }}
                          <v-tooltip bottom v-if="parseInt(recipient.is_pic) === 1">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-on="on" v-bind="attrs" style="
                                  height: 16px;
                                  width: 16px;
                                  position: absolute;
                                ">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                  style="height: 22px">
                                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <path
                                      d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                                      fill="#17deb6"></path>
                                  </g>
                                </svg>
                              </span>
                            </template>
                            <span>{{ $t("tooltip.this person is pic") }}</span>
                          </v-tooltip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="font-size-smaller">{{
                          recipient.readed_at
                            ? $t("text.readed-at") +
                            " " +
                            $date(recipient.readed_at).format(
                              "DD MMM YYYY HH:m"
                            ) +
                            " WIB"
                            : $t("text.unread")
                        }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="attachment-list mb-2" v-if="singleDisposition && singleDisposition.file_name">
                  <span class="d-block pl-3 mt-4 text-bolder">{{
                    $t("label.disposition attachment list")
                    }}</span>
                  <ul>
                    <li class="file--list">
                      <a href="javascript:;" class="server--file">
                        <span>1.</span>
                        {{ truncateFileName(singleDisposition.file_name, 20) }} - {{ singleDisposition.file_size |
                          formatSize }}
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon large class="ml-2" color="blue"
                              v-on:click="downloadAttachment('download', singleDisposition.file_path)" v-on="on"
                              v-bind="attrs">
                              <v-icon>{{ iconDownload }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("tooltip.download-this-file") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon large class="ml-1" color="blue"
                              v-on:click="downloadAttachment('view', singleDisposition.file_path)" v-on="on"
                              v-bind="attrs">
                              <v-icon>{{ iconEye }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("tooltip.view-this-file") }}</span>
                        </v-tooltip>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="6" sm="12" class="mt-sm-4" :class="`${!$vuetify.breakpoint.smAndDown ? 'tracking__border-left' : ''
          }`">
          <h3 class="mt-4 mt-md-0 mb-2 text--bold" v-html="$t('text.disposition-tracking')"></h3>
          <p v-html="$t('text.the-track-of-memo-disposition-process')" class="mb-3"></p>
          <tracking-disposition></tracking-disposition>
        </v-col>
      </v-row>
    </v-card-text>
    <v-overlay v-bind:value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { http } from "@/http/http";
import TrackingDisposition from "@/components/commonComponents/detailComponents/TrackingDisposition.vue";
import {
  mdiSpeedometerSlow,
  mdiSpeedometerMedium,
  mdiSpeedometer,
  mdiCogTransfer,
  mdiCheckBold,
  mdiCheck,
  mdiClockOutline,
  mdiCloudDownload,
  mdiEye,
} from "@mdi/js";

export default {
  name: "memo-view-detail",
  components: {
    TrackingDisposition,
  },
  data: () => ({
    iconSlow: mdiSpeedometerSlow,
    iconMedium: mdiSpeedometerMedium,
    iconFast: mdiSpeedometer,
    iconDirect: mdiCogTransfer,
    iconCheck: mdiCheckBold,
    iconTime: mdiClockOutline,
    iconDownload: mdiCloudDownload,
    iconEye: mdiEye,
    overlay: false
  }),
  computed: {
    ...mapState("disposition", ["singleDisposition", "singleMemo"]),
    ...mapState("user", ["currentUser"]),
    myTask() {
      if (!this.singleDisposition) return [];
      return this.singleDisposition;
    },
    recipients() {
      if (!this.singleMemo) return [];
      return this.singleMemo.penerima;
    },
  },
  methods: {
    urgencyIcon(val) {
      let intVal = parseInt(val);
      let result = this.iconMedium;

      switch (intVal) {
        case 1:
          result = this.iconDirect;
          break;
        case 2:
          result = this.iconSlow;
          break;
        case 3:
          result = this.iconMedium;
          break;
        case 4:
          result = this.iconFast;
          break;
        default:
          result = this.iconMedium;
          break;
      }
      return result;
    },
    urgencyLevel(val) {
      let intVal = parseInt(val);

      let result = "tooltip.disposition-urgency-direct";
      switch (intVal) {
        case 1:
          result = "tooltip.disposition-urgency-normal";
          break;
        case 2:
          result = "tooltip.disposition-urgency-urgent";
          break;
        case 3:
          result = "tooltip.disposition-urgency-very-urgent";
          break;
        default:
          result = "tooltip.disposition-urgency-normal";
          break;
      }
      return result;
    },
    showCompleteFeedback(title, val) {
      if (!val) return;
      this.$dialog.info({
        text: val,
        title: title ? title : this.$t("msgbox.feedback-detail"),
        persistent: true,
        actions: [
          {
            text: this.$t("button.ok"),
            color: "success",
            key: false,
            icon: mdiCheck,
          },
        ],
      });
    },
    downloadAttachment(mode, filePath) {
      if (!filePath) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };

      this.updateOverlay(true);

      http
        .get(`/memo/mydisposition/${this.singleDisposition.id_hash}/attachment?file_path=${filePath}`, options)
        .then((response) => {
          if (response.status === 500) return this.updateOverlay(false);
          let arrImageType = ["image/jpeg", "/image/png"];
          if (response) {
            this.updateOverlay(false);
            if (mode === "download") {
              let dfileURL = window.URL.createObjectURL(new Blob([response]));
              let dfileLink = document.createElement("a");

              dfileLink.href = dfileURL;
              dfileLink.setAttribute("download", this.singleDisposition.file_name);
              document.body.appendChild(dfileLink);

              dfileLink.click();
            } else {
              if (arrImageType.includes(this.singleDisposition.file_type)) {
                let file = new File([response], "Download.png", {
                  type: "image/png",
                });
                var fileURL = URL.createObjectURL(file);
              } else if (this.singleDisposition.file_type === "application/pdf") {
                var file = new Blob([response], {
                  type: "application/pdf;base64",
                });
                fileURL = URL.createObjectURL(file);
              }
              window.open(fileURL);
            }
          }
        });
    },
    updateOverlay(val) {
      this.overlay = val;
    },
  },
  filters: {
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
};
</script>

<template>
   <v-dialog v-model="commentDialog" persistent max-width="600" overlay-opacity=".8">
     <v-card class="pb-1">
       <v-card-title class="headline">
         {{ $t("card.feedback form") }}
       </v-card-title>
       <v-card-text>
         <v-row>
           <v-col cols="12" v-html="submitOptions ? submitOptions.commentDesc : ''">
           </v-col>
           <v-col>
             <v-textarea outlined name="input-7-4" :label="$t('label.comment')" v-model="form.comment"
               hide-details=""></v-textarea>
             <v-alert v-if="$v.form.comment.$error && !$v.form.comment.required" dense type="error" :icon="iconAlert"
               class="modal-alert v-alert--narrow v-alert--bubble top--left mb-0 mt-3">
               {{ $t("error.comment-must-be-filled") }}
             </v-alert>
             <v-alert v-if="$v.form.comment.$error && !$v.form.comment.minLength" dense type="error" :icon="iconAlert"
               class="modal-alert v-alert--narrow v-alert--bubble top--left mb-0 mt-3">
               {{
                 `${$t("error.comment-min-length")} 10 ${$t("label.characters")}`
               }}
             </v-alert>
             <v-alert v-if="$v.form.comment.$error && !$v.form.comment.maxLength" dense type="error" :icon="iconAlert"
               class="modal-alert v-alert--narrow v-alert--bubble top--left mb-0 mt-3">
               {{
                 `${$t("error.comment-max-length")} 250 ${$t(
                   "label.characters"
                 )}`
               }}
             </v-alert>
           </v-col>
         </v-row>
         <v-row>
           <v-col cols="12">
             <hr style="border-top: 1px solid #ccc;margin-bottom: 1rem;border-bottom: 0px;" />
             <p style="margin-bottom: 0px;">{{ $t('text.feedback attachments') }}</p>
             <div class="attachment-list mt-4" v-if="form.attachments && form.attachments.length > 0">
               <p class="mb-1 text--avore-grey" v-html="$t('text.new-attachment-list')"></p>
 
               <ol>
                 <li v-for="item in form.attachments" v-bind:key="item.id" v-bind:value="item.id" class="file--list">
                   <a href="javascript:;">
                     {{ item.name }} - {{ item.size | formatSize }}
                     <span v-if="item.error">{{ item.error }}</span>
                     <span v-else-if="item.success">success</span>
                     <span v-else-if="item.active">active</span>
                     <span v-else></span>
 
                     <v-tooltip bottom>
                       <template v-slot:activator="{ on, attrs }">
                         <v-btn icon class="ml-2" color="red" v-on:click="deleteAttachment(item)" v-on="on" v-bind="attrs">
                           <v-icon>{{ iconDelete }}</v-icon>
                         </v-btn>
                       </template>
                       <span>{{ $t("tooltip.delete-this-file") }}</span>
                     </v-tooltip>
                   </a>
                 </li>
               </ol>
             </div>
             <v-tooltip top>
               <template v-slot:activator="{ on, attrs }">
                 <file-upload input-id="create-general" v-on="on" v-bind="attrs"
                   class="mt-4 att-button v-btn v-btn--is-elevated v-btn--has-bg v-size--default blue-grey"
                   post-action="/upload/post" extensions="jpg,jpeg,png,pdf,doc,docx,xls,xlsx,zip,rar,ppt,pptx"
                   accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation
                     , application/vnd.rar, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword,
                     application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip" v-bind:multiple="true" v-bind:size="1024 * 1024 * 35" v-bind:active="true"
                   v-bind:maximum="totalAttCount" v-model="form.attachments" v-on:input-filter="inputFilter"
                   v-on:input-file="inputFile" ref="upload" v-bind:title="$t('tooltip.add-attachments')">
                   <v-icon class="text--white">{{ iconAttachment }}</v-icon>
                 </file-upload>
               </template>
               <span>{{ $t("tooltip.add-attachments") }}</span>
             </v-tooltip>
           </v-col>
         </v-row>
       </v-card-text>
       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn color="red darken-2" text @click="cancelSelect">
           <v-icon class="mr-1">{{ iconCancel }}</v-icon>
           {{ $t("button.cancel") }}
         </v-btn>
 
         <v-btn color="blue darken-1" text @click="emitForm" v-if="submitOptions">
           <v-icon class="mr-1">{{ submitOptions.btnIcon }}</v-icon>
           {{ $t(submitOptions.btnText) }}
         </v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
 </template>
 <script>
 import { mdiAlertCircleOutline, mdiSend, mdiCloseCircleOutline, mdiAttachment, mdiDeleteForever } from "@mdi/js";
 import { validationMixin } from "vuelidate";
 import VueUploadComponent from "vue-upload-component";
 
 const { required, minLength, maxLength } = require("vuelidate/lib/validators");
 
 export default {
   name: "insert-comment",
   components: {
     "file-upload": VueUploadComponent,
   },
   props: {
     showFeedbackDialog: { type: Boolean },
     submitOptions: { type: Object },
   },
   data: () => ({
     form: {
       comment: null,
       attachments: undefined
     },
     iconAlert: mdiAlertCircleOutline,
     iconSubmit: mdiSend,
     iconCancel: mdiCloseCircleOutline,
     iconAttachment: mdiAttachment,
     iconDelete: mdiDeleteForever,
     totalAttCount: 5,
   }),
   mixins: [validationMixin],
   validations: {
     form: {
       comment: { required, minLength: minLength(10), maxLength: maxLength(250) },
     }
   },
   computed: {
     commentDialog: {
       get() {
         return this.showFeedbackDialog;
       },
       set(val) {
         this.$emit("showHideFeddbackDialog", val);
       },
     },
   },
   methods: {
     emitForm() {
       this.$v.$touch();
       if (this.$v.$invalid) return;
 
       this.$emit("emitFeedback", this.form);
       this.commentDialog = false;
 
       setTimeout(() => {
         this.form = {
           comment: null,
           attachments: undefined
         }
         this.$v.$reset();
       }, 300);
     },
     cancelSelect() {
       this.form = {
         comment: null,
         attachments: undefined
       }
       this.$v.$reset();
       this.$emit("emitFeedback", null);
       this.commentDialog = false;
     },
     inputFilter(newFile, oldFile, prevent) {
       if (newFile && !oldFile) {
         if (
           parseInt(newFile.file.size) >= 35000000 ||
           parseInt(newFile.file.size) + parseInt(this.totalAttSize) >= 35000000
         ) {
           this.$dialog.notify.error(
             this.$t("error.attachment-size-max-35-mb"),
             {
               position: "top-left",
               timeout: 3000,
             }
           );
           return prevent();
         }
         if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
           return prevent();
         }
         if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
           return prevent();
         }
       }
     },
     deleteAttachment(file) {
       if (!file) return;
       this.$refs.upload.remove(file);
     },
     inputFile(newFile, oldFile) {
       if (newFile && !oldFile) {
         // add
         this.totalAttSize += parseInt(newFile.size);
         if (!this.form.attachments) return;
         if (this.form.attachments.length === 1) {
           return this.$dialog.notify.success(
             this.$t("msgbox.feedback attachment will upload on submit"),
             {
               position: "top-left",
               timeout: 3000,
             }
           );
         }
       }
       if (newFile && oldFile) {
         // update
         return;
       }
       if (!newFile && oldFile) {
         // remove
         //send to backend to remove by file_id
         this.totalAttSize -= parseInt(oldFile.size);
         this.deleteAttachment(oldFile);
       }
     },
   },
   filters: {
     formatSize(size) {
       if (!size) return;
       if (size > 1024 * 1024 * 1024 * 1024) {
         return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
       } else if (size > 1024 * 1024 * 1024) {
         return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
       } else if (size > 1024 * 1024) {
         return (size / 1024 / 1024).toFixed(2) + " MB";
       } else if (size > 1024) {
         return (size / 1024).toFixed(2) + " KB";
       }
       return size.toString() + " B";
     },
   },
 };
 </script>
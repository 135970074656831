<template>
  <v-dialog
    v-model="commentDialog"
    persistent
    max-width="600"
    overlay-opacity=".8"
  >
    <v-card class="pb-1">
      <v-card-title class="headline">
        {{ $t("card.type-comment") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            v-html="submitOptions ? submitOptions.commentDesc : ''"
          >
          </v-col>
          <v-col>
            <v-textarea
              outlined
              name="input-7-4"
              :label="$t('label.comment')"
              v-model="comment"
              hide-details=""
            ></v-textarea>
            <v-alert
              v-if="$v.comment.$error && !$v.comment.required"
              dense
              type="error"
              :icon="iconAlert"
              class="modal-alert v-alert--narrow v-alert--bubble top--left mb-0 mt-3"
            >
              {{ $t("error.comment-must-be-filled") }}
            </v-alert>
            <v-alert
              v-if="$v.comment.$error && !$v.comment.minLength"
              dense
              type="error"
              :icon="iconAlert"
              class="modal-alert v-alert--narrow v-alert--bubble top--left mb-0 mt-3"
            >
              {{
                `${$t("error.comment-min-length")} 10 ${$t("label.characters")}`
              }}
            </v-alert>
            <v-alert
              v-if="$v.comment.$error && !$v.comment.maxLength"
              dense
              type="error"
              :icon="iconAlert"
              class="modal-alert v-alert--narrow v-alert--bubble top--left mb-0 mt-3"
            >
              {{
                `${$t("error.comment-max-length")} 250 ${$t(
                  "label.characters"
                )}`
              }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-2" text @click="cancelSelect">
          <v-icon class="mr-1">{{ iconCancel }}</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          @click="emitComment"
          v-if="submitOptions"
        >
          <v-icon class="mr-1">{{ submitOptions.btnIcon }}</v-icon>
          {{ $t(submitOptions.btnText) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiAlertCircleOutline, mdiSend, mdiCloseCircleOutline } from "@mdi/js";
import { validationMixin } from "vuelidate";
const { required, minLength, maxLength } = require("vuelidate/lib/validators");

export default {
  name: "insert-comment",
  props: {
    showCommentDialog: { type: Boolean },
    submitOptions: { type: Object },
  },
  data: () => ({
    comment: null,
    iconAlert: mdiAlertCircleOutline,
    iconSubmit: mdiSend,
    iconCancel: mdiCloseCircleOutline,
  }),
  mixins: [validationMixin],
  validations: {
    comment: { required, minLength: minLength(10), maxLength: maxLength(250) },
  },
  computed: {
    commentDialog: {
      get() {
        return this.showCommentDialog;
      },
      set(val) {
        this.$emit("showHideCommentDialog", val);
      },
    },
  },
  methods: {
    emitComment() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit("commentFilled", this.comment);
      this.commentDialog = false;

      setTimeout(() => {
        this.comment = null;
        this.$v.$reset();
      }, 500);
    },
    cancelSelect() {
      this.comment = null;
      this.$v.$reset();
      this.$emit("commentFilled", null);
      this.commentDialog = false;
    },
  },
};
</script>
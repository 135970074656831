<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind:class="`${dispositionStatus.class}--text mr-2 icon__size-50`"
          v-on="on"
          v-bind="attrs"
        >
          {{ dispositionStatus.icon }}
        </v-icon>
      </template>
      <span>{{
        `${$t("tooltip.disposition-is-" + dispositionStatus.status)}`
      }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import {
  mdiDotsVertical,
  mdiCheckCircleOutline,
  mdiSort,
  mdiCog,
  mdiCommentQuoteOutline,
  mdiTransfer,
} from "@mdi/js";
import toolbarMenu from "@/constants/toolbarmenu";

export default {
  name: "header-right",
  props: {
    dispositionStatus: Object,
  },
  data: () => ({
    iconMenu: mdiDotsVertical,
    iconCheck: mdiCheckCircleOutline,
    iconSort: mdiSort,
    iconCog: mdiCog,
    iconFeedback: mdiCommentQuoteOutline,
    iconTransfer: mdiTransfer,
    menus: toolbarMenu.inboxSortMenu,
  }),
};
</script>